import { Injectable, inject } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class DevLoadingGuard {
  private router = inject(Router);

  canLoad(): boolean | UrlTree {
    return !environment.production ? true : this.router.createUrlTree(['/artworks']);
  }
}
