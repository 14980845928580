import { enableProdMode } from '@angular/core';
import { enableAkitaProdMode, persistState } from '@datorama/akita';
import { TranslateService } from '@ngx-translate/core';
import { browserTracingIntegration, init } from '@sentry/angular-ivy';
import { Amplify } from 'aws-amplify';
import { environment } from '../../environments/environment';
import { AppConstants } from '../shared/config/app.constants';

export function initializeCoreFactory(ts: TranslateService) {
  return (): Promise<boolean> => {
    if (environment.production) {
      enableProdMode();
      enableAkitaProdMode();
    }

    if (environment.sentry?.enabled) {
      init({
        ...environment.sentry,
        autoSessionTracking: true,
        tracesSampleRate: 0.2,
        beforeSend(event, hint) {
          const error = hint?.originalException as Error;
          if (error && error.message.match(/40[349]/)) {
            return null;
          }
          return event;
        },
        integrations: [browserTracingIntegration()],
      });
    }

    persistState({
      key: 'na-app',
      include: [
        'auth.session',
        'DataTable-Archive-List.ui',
        'DataTable-Artworks-List.ui',
        'DataTable-Publication-List.ui',
        'DataTable-Agents-List.ui',
        'DataTable-Auctions-List.ui',
        'DataTable-Exhibitions-List.ui',
        'DataTable-Resource-Select-List.ui',
        'DataTable-Indexing-Terms-List.ui',
        'DataTable-Committee-List.ui',
        'DataTable-Global-Texts-List.ui',
        'DataTable-Essays-List.ui',
        'DataTable-Artwork-Sets-List.ui',
        'DataTable-Geographic-Location-List.ui',
        'entity-reports',
        'DataTable-Storage-Location-List.ui',
        'DataTable-Condition-Assessment-List.ui',
        'DataTable-Artworks-Changelog-List.ui',
        'DataTable-Markings-List.ui',
        'DataTable-TaskManager-Task-List.ui',
        'DataTable-TaskManager-Entity-Task-List.ui',
        'DataTable-Global-Notes-List.ui',
      ],
      // preStorageUpdateOperator: () => (window as any).Cypress ? debounceTime(0) : debounceTime(2000),
    });

    ts.use(AppConstants.APP_TRANSLATE_DEFAULT_LANG);

    Amplify.configure({
      Storage: {
        S3: {
          bucket: environment.s3?.albumBucketName,
          region: environment.s3?.bucketRegion,
        },
      },
      Auth: {
        Cognito: {
          userPoolId: environment.cognito.userPoolId,
          userPoolClientId: environment.cognito.clientId,
          identityPoolId: environment.s3?.identityPoolId as string,
        },
      },
    });

    return Promise.resolve(true);
  };
}
