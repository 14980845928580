import { ErrorHandler, inject, provideAppInitializer } from '@angular/core';
import { Router } from '@angular/router';
import { TraceService, createErrorHandler } from '@sentry/angular-ivy';

export const SentryProviders = [
  {
    provide: ErrorHandler,
    useValue: createErrorHandler({
      showDialog: false,
    }),
  },
  {
    provide: TraceService,
    deps: [Router],
  },
  provideAppInitializer(() => {
    const initializerFn = () => {
      inject(TraceService);
    };
    return initializerFn();
  }),
];
